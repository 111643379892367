
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchBoxDialogRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog' }, _createElement('div', { 'className': 'clear-self cm_search-box_container' }, _createElement('div', { 'className': 'dialog-container' }, _createElement('div', {
        'className': 'dialog-content',
        'onSubmit': this.preventFormSubmission
    }, _createElement('div', { 'className': 'dialog-header' }, _createElement('div', { 'className': 'dialog-title' }, 'Search'), _createElement('div', {
        'className': 'cm_search-box-dialog_close-button close',
        'onClick': this.close
    }, _createElement('svg', {
        'className': 'cm_icon cm_icon-times',
        'height': '20px',
        'role': 'img',
        'viewBox': '0 0 22 22'
    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' })))), _createElement('div', { 'className': 'cm_search-box_form-container' }, [
        [this.searchInput(function () {
                return _createElement('div', { 'className': 'form-input cm_searchInput' }, 'Search by keyword, brand or SKU');
            }, {
                widgetName: 'undefined',
                items: undefined
            })],
        _createElement('span', {
            'className': 'cm_search-box_clear-container',
            'key': '5812'
        }, this.inputNotEmpty ? _createElement('span', {
            'className': 'cm_search-box_clear',
            'onClick': this.clearInput,
            'key': '904'
        }, '\n    ', window.innerWidth > 768 ? '\u2715' : 'Clear', '\n  ') : null),
        _createElement('div', {
            'className': 'cm_search-box_submit search-button',
            'title': 'Search',
            'aria-label': 'search button',
            'data-cm-role': 'add-query',
            'key': '5814'
        }, [_createElement('svg', {
                'className': 'icon icon-search',
                'id': 'icon-search',
                'xmlns': 'http://www.w3.org/2000/svg',
                'width': '50',
                'height': '50',
                'viewBox': '0 0 50 50',
                'fill': 'none',
                'key': '12060'
            }, _createElement('path', {
                'd': 'M15.803 2.71397C17.409 1.21508 19.9552 1.27408 21.4901 2.84575L24.3234 5.74693L5.61262 23.2094L2.77932 20.3082C1.24443 18.7365 1.30211 16.2474 2.90815 14.7485L15.803 2.71397Z',
                'fill': 'white'
            }), _createElement('rect', {
                'width': '7.83794',
                'height': '23.7547',
                'rx': '2',
                'transform': 'matrix(0.970334 -0.241769 0.252291 0.967651 1.35352 16.875)',
                'fill': 'white'
            }), _createElement('rect', {
                'width': '7.47617',
                'height': '24.2363',
                'rx': '2',
                'transform': 'matrix(0.23768 -0.971343 0.973732 0.227696 15.3242 8.46094)',
                'fill': 'white'
            }), _createElement('path', {
                'd': 'M30.4939 43.6495C29.6656 44.3803 28.3868 44.3147 27.6375 43.5031L25.7571 41.4663C25.0078 40.6547 25.0719 39.4043 25.9002 38.6736L39.8935 26.3283C40.7218 25.5975 42.0006 25.6631 42.7499 26.4747L44.6303 28.5115C45.3795 29.3231 45.3155 30.5734 44.4872 31.3042L30.4939 43.6495Z',
                'fill': 'white'
            }), _createElement('rect', {
                'width': '7.47617',
                'height': '23.9228',
                'rx': '2',
                'transform': 'matrix(0.23768 -0.971343 0.973732 0.227696 7.15234 39.2227)',
                'fill': 'white'
            }), _createElement('rect', {
                'width': '7.63068',
                'height': '25.7768',
                'rx': '2',
                'transform': 'matrix(0.978757 -0.205025 0.214114 0.976809 32.6934 7.55664)',
                'fill': 'white'
            }), _createElement('rect', {
                'width': '6.95981',
                'height': '15.6596',
                'transform': 'matrix(0.740635 -0.671908 0.688488 0.725248 34.0645 38.6426)',
                'fill': 'white'
            }))])
    ]), [this.dropdown(function () {
            function repeatSections1(sections, sectionsIndex) {
                return [sections(function () {
                        function repeatItems1(items, itemsIndex) {
                            return [items(function () {
                                    function mergeProps_text(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    function mergeProps_product(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    function onError1_facetValue(e) {
                                        e.target.classList.add('cm_hide');
                                    }
                                    function mergeProps_facetValue(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    return this.template === 'text' ? _createElement('div', mergeProps_text({ 'className': 'cmTemplate_text' }, { dangerouslySetInnerHTML: { __html: this.textHighlight } })) : this.template === 'product' ? _createElement('div', { 'className': 'cmTemplate_product' }, _createElement('div', { 'className': 'image-block' }, _createElement('img', {
                                        'src': this.imageOrDefault(this.images_image_thumbnail1 || this.image_thumbnail || this.images_image_standard1 || this.image_standard),
                                        'alt': 'Image of ' + (this.removeHTML(this.sku) || this.removeHTML(this.mpn) || this.removeHTML(this.name)),
                                        'onError': this.onImageError
                                    })), _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_product({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.name } })), _createElement('div', { 'className': 'price' }, this.formatPrice(this.price)))) : this.template === 'facetValue' ? _createElement('div', { 'className': 'cmTemplate_facetValue' }, this.imageUrl ? _createElement('div', {
                                        'className': 'image-block',
                                        'key': '35'
                                    }, _createElement('img', {
                                        'src': this.imageUrl,
                                        'alt': 'Image of ' + this.facetValue,
                                        'onError': onError1_facetValue.bind(this)
                                    })) : null, _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_facetValue({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.value } })), this.hitCount ? _createElement('span', {
                                        'className': 'hit-count',
                                        'key': '335'
                                    }, '(', this.hitCount, ')') : null)) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'section' + (this.sectionTitle ? ` ${ this.sectionTitle }` : '') }, this.sectionTitle ? _createElement('div', {
                            'className': 'section-title-div',
                            'key': '94'
                        }, _createElement('div', { 'className': 'item section-title' }, _createElement('b', {}, this.totalHits > 1 ? `${ this.totalHits } ` : '', this.sectionTitle), !!this.vehicleString ? ` for ${ this.vehicleString }` : '')) : null, _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_items' },
                            _map(this.items, repeatItems1.bind(this))
                        ]));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'dialog-body cm_autocomplete cm_dropdown' }, _createElement.apply(this, [
                'div',
                { 'className': 'cmRepeater_sections' },
                _map(this.sections, repeatSections1.bind(this))
            ]), this.browseAllButton ? [[this.browseAllButton(function () {
                        return _createElement('a', { 'className': 'item button-item cm_browseAllButton' }, 'Browse all ', this.totalHits, ' products');
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]] : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]))));
}
        export const componentNames = ["cm:searchInput","cm:browseAllButton","cm:dropdown"]