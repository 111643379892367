//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-244:_800,_9516,_5044,_1156,_8868,_1004,_8079,_3924;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-244')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-244', "_800,_9516,_5044,_1156,_8868,_1004,_8079,_3924");
        }
      }catch (ex) {
        console.error(ex);
      }